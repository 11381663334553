import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_f7_view = _resolveComponent("f7-view");
  const _component_f7_app = _resolveComponent("f7-app");
  return _openBlock(), _createBlock(_component_f7_app, _normalizeProps(_guardReactiveProps($props.f7Params)), {
    default: _withCtx(() => [_createVNode(_component_f7_view, {
      url: "/",
      main: true,
      class: "safe-areas",
      "master-detail-breakpoint": 768,
      "stack-pages": true,
      "ios-swipe-back-active-area": $data.iosSwipeBackActiveArea,
      "ios-page-load-delay": 300
    }, null, 8, ["ios-swipe-back-active-area"])]),
    _: 1
  }, 16);
}