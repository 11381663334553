// 按需引入api mock配置
const mockConfig = {
    enabled: false,
    // apis: [iam,home],
    apis: [],
    // 在白名单中的URL跳过mock
    whiteList: [],
    // 在黑名单中的URL使用mock
    blackList: []
}

export default mockConfig
