import {createApp} from "vue";
import LoadingComponent from "@/directives/loading/LoadingComponent";

//添加loading
const appendLoading = (el) => {
    el.classList.add('loading-relative');
    Array.from(el.children).forEach(node => {
        const classList = Array.from(node.classList);
        if (!classList.includes('loading-container')) {
            node.classList.add('hidden-on-loading');
        }
    })
    el.appendChild(el.loadingInstance.$el);
}

//移除loading
const removeLoading = (el) => {
    el.classList.remove('loading-relative');
    Array.from(el.children).forEach(node => {
        const classList = Array.from(node.classList);
        if (classList.includes('hidden-on-loading')) {
            node.classList.remove('hidden-on-loading');
        }
    })
    el.removeChild(el.loadingInstance.$el)
}

export default {

    mounted(el,binding) {
        //loading实列
        const loadingInstance = createApp(LoadingComponent).mount(document.createElement('div'));
        el.loadingInstance = loadingInstance;
        if (binding.value) {
            appendLoading(el)
        }
    },

    updated(el,binding){
        if (binding.value !== binding.oldValue) {
            //不带修饰符情况下添加和移除loading即可
            binding.value ? appendLoading(el) : removeLoading(el);
        }
    }

}
