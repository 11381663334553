import applicationService from "@/applications/service/application-service";

const applicationManager = {

    token: '',
    getTokenTime: 0,
    deviceFlag: '',
    //deviceFlag: 'test.android',

    getDeviceFlag() {
        const getDeviceFlagInterval = setInterval(function () {
            if (this.deviceFlag) {
                clearInterval(getDeviceFlagInterval);
                return;
            }
            window.EappApi.getDeviceInfo(function (data) {
                this.deviceFlag = data.deviceId;
                console.log('deviceFlag:' + this.deviceFlag);
            }.bind(this))
        }.bind(this), 1000);
    },

    getToken() {
        const getTokenInterval = setInterval(async function () {
            this.getTokenTime++;
            if (this.token || this.getTokenTime === 10) {
                clearInterval(getTokenInterval);
                await applicationService.saveInstall();
                return;
            }
            window.EappApi.getAbroadPushToken(null, function (data) {
                this.token = data.DeviceToken;
                console.log('token:' + this.token);
            }.bind(this))
        }.bind(this), 3000);
    },

    getDeviceFlagAndToken() {
        this.getDeviceFlag();
        this.getToken();
    }

}

export default applicationManager
