import loading from './loading'

const directives = {
    loading
}

export default {

    install(app) {
        Object.keys(directives).forEach(key => {
            app.directive(key,directives[key])
        })
    }

}
