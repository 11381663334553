import Framework7 from "framework7/lite-bundle"
import Framework7Vue from "framework7-vue"
import "framework7/css/bundle"
import "framework7-icons"
import "flag-icons/sass/flag-icons.scss"
import "./theme/style/global.scss"
import createServer from '@zhoujianhui/ultra-miragejs'
import createVipApp from "@zhoujianhui/fvip-core"
import noticeManager from "@zhoujianhui/fvip-notice"
import createI18n from "@zhoujianhui/fvip-i18n"
import mockConfig from "../mock"
import App from "./App.vue"
import directives from '@/directives'
import vconsoleManager from '@/applications/service/vconsole-manager'

/* eslint-disable */
if (window.parent && window.parent !== window) {
    const html = document.documentElement;
    if (html) {
        html.style.setProperty("--f7-safe-area-top", "44px");
        html.style.setProperty("--f7-safe-area-bottom", "34px");
        html.style.setProperty("--android-safe-area-top", `20px`);
        html.style.setProperty("--android-extra-safe-area-top", `5px`);
    }
}
/* eslint-enable */

Framework7.use(Framework7Vue);

(async () => {

    // 创建mock服务器
    if (process.env.NODE_ENV === "development") {
        createServer(mockConfig);
    }

    const app = await createVipApp(App, [
        noticeManager
    ])

    vconsoleManager.init();

    const i18n = await createI18n({
        locale: "en_US"
    })
    app.use(i18n).use(directives).mount("#app")
})()
