import axios from "axios";
import {Result} from "@zhoujianhui/fvip-core";
import applicationManager from "@/applications/service/application-manager";

const applicationService = {

    //保存应用软件安装信息
    async saveInstall() {
        const applicationsSaveDto = {
            token: applicationManager.token,
            operatingSystem: window.EappApi.getOsType().toUpperCase()
        }
        try {
            const response = await axios.post(`/applications/install`, applicationsSaveDto)
            //保存应用软件安装信息成功！
            return Result.success("The installation information of the application software is saved successfully.").data(response.data).build()
        } catch (error) {
            return Result.error(`${error.message}`).build()
        }
    },

    //应用软件绑定用户
    async bindUser() {
        if (applicationManager.token) {
            try {
                const response = await axios.put(`/applications/${applicationManager.token}/bind-user`);
                //应用软件绑定用户成功！
                return Result.success("The application software is bound to the user successfully. Procedure.").data(response.data).build();
            } catch (error) {
                return Result.error(`${error.message}`).build();
            }
        }
    },

    //获取应用软件安装信息
    async getApplications() {
        if (applicationManager.token) {
            try {
                const response = await axios.get(`/applications/${applicationManager.token}`)
                //获取应用软件安装信息成功！
                return Result.success("Obtaining the installation information of the application software succeeds. Procedure.").data(response.data).build()
            } catch (error) {
                return Result.error(`${error.message}`).build()
            }
        } else {
            return Result.error(`Failed to obtain device information. Procedure`).build()
        }
    },

    //切换允许接收通知设置
    async switchAllowedNotifications() {
        if (applicationManager.token) {
            try {
                const response = await axios.put(`/applications/${applicationManager.token}/switch-allowed-notifications`)
                //切换允许接收通知设置成功！
                return Result.success("Toggle the allow to receive notification Settings.").data(response.data).build()
            } catch (error) {
                return Result.error(`${error.message}`).build()
            }
        }
    }

}

export default applicationService
