import { configManager } from '@zhoujianhui/fvip-core'
import Vconsole from 'vconsole'

const vconsoleManager = {

    init(){
        if (configManager.getConfig().vconsole && configManager.getConfig().vconsole.enable) {
            new Vconsole();
        }
    }

}

export default vconsoleManager
